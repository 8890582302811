import axios from 'axios';
import Cookies from 'js-cookie';

import environment from '../../src/configs/environment';
import PositionContext from '../components/common/PositionContext';

export const getCValues = () => {
  const trackingCookies = {};

  for (let i = 0; i <= 9; i++) {
    const cookieName = `c${i}`;
    const cookieValue = Cookies.get(cookieName);
    if (cookieValue) {
      trackingCookies[cookieName] = cookieValue;
    }
  }

  return trackingCookies;
};

const api = {
  getCompanyJobs: (id, pass, q, l) =>
    axios.get(
      `${environment.talroo_search_api}`,
      {
        params: Object.assign(
          {
            format: 'json',
            logo: 1,
            limit: 3,
            link: 1,
          },
          getCValues(),
          {
            id,
            pass,
            q,
            l,
          },
        ),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.data.jobs).catch((error) => {
      throw error;
    }),

  getJobs: (q, l, uid, limit, start, t1) =>
    axios.get(
      `${environment.talroo_search_api}`,
      {
        params: Object.assign(
          {
            id: 6241,
            pass: 'ZA73siQGREz7L4lG',
            format: 'json',
            link: 1,
            logo: 1,
            rt_lookback: 300,
          },
          getCValues(),
          {
            q: q ? q : '',
            l,
            uid,
            limit,
            start,
            t1,
          },
        ),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      throw error;
    }),

  getRecommended: (query, count, loc = null) =>
    axios.get(
      `${environment.recommend_api}${query}`,
      {
        params: {
          rt_blend_q_ratio: 0,
          related_count: count,
          location: loc,
        },
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': `${environment.api_key}`,
        },
      },
    ).then((response) => response.data.related).catch((error) => {
      throw error;
    }),

  getRelated: (jobId, q, l) =>
    axios.get(
      `${environment.related_api}${jobId}`,
      {
        params: Object.assign(
          getCValues(),
          {
            id: 6245,
            pass: 'jAz4TqSoXxEoiUep',
            format: 'json',
            link: 1,
            logo: 1,
            q,
            l,
          },
        ),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.data.jobs).catch((error) => {
      throw error;
    }),

  getUIDBehavior: (uid, l) =>
    axios.get(
      `${environment.recommend_search_api}`,
      {
        params: Object.assign(
          {
            id: 6244,
            pass: 'HgKiapqXd416cCRC',
            format: 'json',
            rt_fallback: 0,
            rt_blend_q_ratio: 0,
            logo: 1,
            link: 1,
          },
          getCValues(),
          {
            uid,
            l,
          },
        ),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.data.jobs).catch((error) => {
      throw error;
    }),

  subscribeContact: (email, keyword, zipCode) =>
    axios.post(
      environment.subscriber_api,
      {
        email,
        keyword,
        zipcode: `${zipCode}`,
        tenant_id: environment.tenant_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response).catch((error) => {
      throw error;
    }),

  unsubscribeContact: (email) =>
    axios.delete(
      environment.subscriber_api,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          email,
          tenant_id: environment.tenant_id
        },
      },
    ).then((response) => response).catch((error) => {
      throw error;
    }),
};

api.contextType = PositionContext;

export default api;
