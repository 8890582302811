/**
 * Specific values for the DEV environment.
 */
const api_endpoint = 'https://api.dev.newjobapplications.com';

const environment = {
  env: 'dev',
  number_of_jobs: 20,
  pushnami_id: '5f847adb55da314ff00a7b1c',
  recommend_search_api: `${api_endpoint}/reco/search`,
  recommend_api: `${api_endpoint}/reco/related/query/to/query/`,
  related_api: `${api_endpoint}/reco/search/by/job/`,
  subscriber_api: `${api_endpoint}/subscription`,
  talroo_search_api: `${api_endpoint}/reco/search`,
  api_key: 'DUFhslfZeP4NS0n8ToPWA7wsDPaRXgV47RJLCO0a',
  tenant_id: '3elac22qlkkr4t2ig01t6cued7',
  default_api_format: 'json',
  default_api_link: 1,
  default_api_logo: 1,
  j2c_web_api_endpoint: 'https://0nivjwo2nf.execute-api.us-east-1.amazonaws.com/dev/proxies',
  j2c_web_api_bearer_token: 'FEHfiZFOincvDBx5jMKYdump0xdw00OtZHKaicLk9NeWhS2QK67fXO9nbMzUAVEi',
  job_list_ads: [
    {
      type: 'adSense',
      client: 'ca-pub-9628061608401614',
      slot: '9693794091',
      format: 'link',
      responsive: 'true',
      style: { display: 'block' }
    },
    {
      type: 'pop',
      id: 'd4e4fcd3-3593-47d4-ba71-c0b704df7c4d',
      cid: 'Y3VzdG9tZXJfaWQ6MTk0'
    },
    {
      type: 'pop',
      id: '926f7a5a-32a9-4070-b938-c24b57164f46',
      cid: 'Y3VzdG9tZXJfaWQ6MTk0'
    },
    { type: 'none' }
  ],
  reg_path_ads: [
    {type: 'none'},
    {
      type: 'pop',
      id: '70354993-72a4-4a9f-9bd4-904a6d04a626',
      cid: 'Y3VzdG9tZXJfaWQ6MTk0',
      title: 'Positions you might like'
    }
  ],
  talroo_terms_page: 'https://www.talroo.com/terms/?utm_source=dev.thejobwizard.com&utm_campaign=jobs2careers&utm_content=terms',
  talroo_privacy_page: 'https://www.talroo.com/privacy/?utm_source=dev.thejobwizard.com&utm_campaign=jobs2careers&utm_content=privacy',
  talroo_ccpa_dns_page: 'https://www.talroo.com/ccpa-dns/?utm_source=dev.thejobwizard.com&utm_campaign=jobs2careers&utm_content=dnsmpi',
  talroo_ccpa_privacy_page: 'https://www.talroo.com/ccpa-privacy/?utm_source=dev.thejobwizard.com&utm_campaign=jobs2careers&utm_content=ccpa-privacy',
  job_display: {
    use_pop: false,
    ad_cid: null,
    ad_id: null,
  },
};

export default environment;
