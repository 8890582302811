import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet/es/Helmet';

import themes from './assets/themes';
import PositionContext from './components/common/PositionContext';
import withQueryParameters from './hocs/withQueryParameters';
import Nav from './Nav';
import { geoToLocation } from './utils/locationUtils';

class App extends Component {
  constructor(props) {
    super(props);

    const { company, geo, regPath } = this.props;
    const googleLocation = geoToLocation(geo);
    const zipCode = Cookies.get('zip') || '';
    const uid = Cookies.get('uid') || '';
    // Get the companyTitle from the given Query parameter
    // and put it in the Context.
    const companyTitle = company in themes ? themes[company].company_title : '';

    this.state = {
      // User info
      position: Cookies.get('position') || '',
      zipCode: zipCode,
      googleLocation: googleLocation,
      uid: uid,
      company: company,
      email: Cookies.get('email') || '',
      // Form submission flags
      zipSubmit: !!zipCode,
      submit: !!uid,
      submitPosition: false,
      regPath: !!regPath,
      // Results page storage
      jobs: [],
      companyJobs: [],
      companyTitle: companyTitle,
      relatedJobs: [],
      relatedOpen: false,
      emailOpen: false,
      profile2Open: false,
      uidJobs: [],
      uidOpen: false,
      recommendations: [],
      start: 0,
    };
  }

  updateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  render() {
    const { company } = this.state;
    const theme = company in themes
      ? createMuiTheme(themes[company])
      : createMuiTheme(themes.main);

    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <meta name="theme-color" content={theme.palette.primary.main} />
        </Helmet>
        <div style={{ height: '100%' }}>
          <PositionContext.Provider
            value={{ state: this.state, updateValue: this.updateValue }}>
            <Nav />
          </PositionContext.Provider>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {};

App.contextType = PositionContext;

export default withQueryParameters(App);
